<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8 mb-5">
      <h2 class="text-lg font-medium">{{ i18n('product.title') }}</h2>
    </div>

    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
      class="mb-5"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- START: HTML Tab -->
    <div>
      <div
        class="bg-white w-full h-10 rounded-md flex shadow-sm overflow-hidden intro-y"
      >
        <button
          class="flex-1 max-h-[2.5rem] disabled:cursor-not-allowed"
          :disabled="currentType === 'bitaqaty'"
          :class="[
            currentType === 'bitaqaty'
              ? 'active:scale-100 bg-gradient-to-r bg-[#800b8c] to-[#800b8c90] text-white'
              : 'active:scale-95'
          ]"
          @click="() => setType('bitaqaty')"
        >
          {{ i18n('common.bitaqaty') }}
        </button>
        <button
          class="flex-1 max-h-[2.5rem] disabled:cursor-not-allowed"
          :disabled="currentType === 'zain'"
          :class="[
            currentType === 'zain'
              ? 'active:scale-100 bg-gradient-to-r bg-[#3a9c97] to-[#3a9c9790] text-white'
              : 'active:scale-95'
          ]"
          @click="() => setType('zain')"
        >
          {{ i18n('common.zain') }}
        </button>
      </div>
      <!-- END: HTML Tab -->
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <!-- <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
        <a
        id="products-tab"
        data-toggle="tab"
        data-target="#products"
        href="javascript:;"
        class="py-4 sm:mr-8 active"
        role="tab"
        aria-controls="products"
        aria-selected="true"
        @click="onChangeTab('products')"
        >
        <app-i18n code="product.list.title"></app-i18n>
      </a>
      <a
      id="pending-products-tab"
      data-toggle="tab"
      data-target="#products"
      href="javascript:;"
      class="flex items-center gap-2 py-4 sm:mr-8"
      role="tab"
      aria-selected="false"
      @click="onChangeTab('pending-products')"
      >
      <app-i18n code="product.list.pendingProducts"></app-i18n>
      <Tippy tag="div" class="bg-theme-24 text-white font-bold rounded-full px-1.5" style="border-radius: 50%;"
      :content="isRTL ? 'المنتجات الجديدة المعلقة' : 'New pending products'" v-if="productsBadge > 0">
      {{ productsBadge }}
    </Tippy>
  </a>
</div> -->

        <div class="tab-content">
          <loading-spinner
            type="controller"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <app-product-list-table
            ref="table"
            tab="products"
            :currentType="currentType"
          />
          <!-- <app-product-list-table ref="table" tab="pending-products" v-if="tab == 'pending-products'"></app-product-list-table> -->
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent } from 'vue'
// import xlsx from 'xlsx'
// import feather from 'feather-icons'

import ProductListTable from '@/views/product/product-list-table.vue'

export default defineComponent({
  components: {
    [ProductListTable.name]: ProductListTable
  },
  data() {
    return {
      tab: 'products',
      filterInput: [],
      type: 'bitaqaty'
      // filterFields: [
      //   { label: this.i18n('product.fields.title'), value: 'title' },
      //   { label: this.i18n('product.fields.priceWithVat'), value: 'priceWithVat' },
      //   { label: this.i18n('product.fields.sellerName'), value: 'sellerName' },
      //   { label: this.i18n('product.fields.sellingType'), value: 'sellingType' },
      //   { label: this.i18n('product.fields.status'), value: 'status' }
      // ],
      // ProductStatusPending: ['pendingadd', 'pendingupdate'], // 'shadow'
      // ProductStatusNotPending: ['sold', 'approved', 'rejected'] // 'removed'
      // { key: 'status', operator: 'in', values: [''. '', ''] }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      listLoading: 'product/list/loading',
      destroyLoading: 'product/destroy/loading',
      saveLoading: 'product/form/saveLoading'
      // productsBadge: 'realtime/productsBadge'
    }),
    loading() {
      return this.listLoading || this.destroyLoading || this.saveLoading
    },
    currentType() {
      return this.type
    },
    sellingTypeOptions() {
      return [
        {
          label: this.i18n('product.enumerators.sellingType.normal'),
          value: 'normal'
        },
        {
          label: this.i18n('product.enumerators.sellingType.auction'),
          value: 'auction'
        }
      ]
    },
    statusOptions() {
      return [
        { label: this.i18n('product.enumerators.status.sold'), value: 'sold' },
        {
          label: this.i18n('product.enumerators.status.approved'),
          value: 'approved'
        },
        {
          label: this.i18n('product.enumerators.status.rejected'),
          value: 'rejected'
        },
        {
          label: this.i18n('product.enumerators.status.removed'),
          value: 'removed'
        },
        {
          label: this.i18n('product.enumerators.status.pendingupdate'),
          value: 'pendingupdate'
        },
        {
          label: this.i18n('product.enumerators.status.removed'),
          value: 'removed'
        }
      ]
    },
    filterFields() {
      return [
        // {
        //   label: this.i18n('product.fields.id'),
        //   value: 'id',
        //   type: 'number'
        // },
        {
          label: this.i18n('product.fields.title'),
          value: 'name',
          type: 'text'
        },
        {
          label: this.i18n('product.fields.price'),
          value: 'price',
          type: 'number'
        },
        {
          label: this.i18n('product.fields.sku'),
          value: 'id',
          type: 'text'
        }
        // {
        //   label: this.i18n('product.fields.provider'),
        //   value: 'id',
        //   type: 'text'
        // }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.products')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.products')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'product/list/doFetch',
      doReset: 'product/list/doReset',
      doChangePaginationCurrentPage:
        'product/list/doChangePaginationCurrentPage',
      doDestroy: 'product/destroy/doDestroy'
    }),
    async setType(val) {
      this.type = val
      await this.doFetchFirstPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    // async onChangeTab(tab) {
    //   this.tab = tab
    //   await this.doReset()
    //   await this.doResetFilter()
    // },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      this.filterInput = filter
      // if (Array.isArray(filter)) {
      //   this.filterInput.push(...filter)
      // } else {
      //   this.filterInput.push(filter)
      // }
      // if (!this.filterInput.length) return
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      // switch (this.tab) {
      //   case 'products':
      //     this.filterInput = [{ key: 'status', operator: 'in', values: this.ProductStatusNotPending }]; break
      //   case 'pending-products':
      //     this.filterInput = [{ key: 'status', operator: 'in', values: this.ProductStatusPending }]; break
      // }
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage(orderBy = 'price') {
      const type = this.type
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: [
          ...this.filterInput,
          {
            field: 'provider_name',
            operator: 'equal',
            value: type
          }
        ],
        orderBy,
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    }
  }
})
</script>
<style scoped></style>
