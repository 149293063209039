/* eslint-disable */

import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import IntegerField from '@/shared/fields/integer-field';
import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`product.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`product.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id'), { required: true }),
  title: new StringField('title', label('title'), { required: true }),
  description: new StringField('description', label('description'), { required: true }),
  country: new StringField('country', label('country'), { required: true }),
  city: new StringField('city', label('city'), { required: true }),
  sellerId: new StringField('sellerId', label('sellerId'), { required: true }),
  sellerName: new StringField('sellerName', label('sellerName'), { required: true }),
  parentProductId: new StringField('parentProductId', label('parentProductId')),
  childProductId: new StringField('childProductId', label('childProductId')),
  images: new StringArrayField('images', label('images')),
  deliveryCompaniesIds: new StringArrayField('deliveryCompaniesIds', label('deliveryCompaniesIds')),
  originalPrice: new DecimalField('originalPrice', label('originalPrice')),
  sellingPrice: new DecimalField('sellingPrice', label('sellingPrice')),
  priceWithComm: new DecimalField('priceWithComm', label('priceWithComm')),
  priceWithVat: new DecimalField('priceWithVat', label('priceWithVat')),
  minimumBidding: new DecimalField('minimumBidding', label('minimumBidding')),
  minimumBiddingWithComm: new DecimalField('minimumBiddingWithComm', label('minimumBiddingWithComm')),
  minimumBiddingWithVat: new DecimalField('minimumBiddingWithVat', label('minimumBiddingWithVat')),
  clicks: new IntegerField('clicks', label('clicks')),
  category: new JsonField('category', label('category'), ['en', 'ar']),
  subCategory: new JsonField('subCategory', label('subCategory'), ['en', 'ar']),
  brand: new JsonField('brand', label('brand'), ['en', 'ar']),
  class: new JsonField('class', label('class'), ['en', 'ar']),
  type: new JsonField('type', label('type'), ['en', 'ar']),
  size: new JsonField('size', label('size'), ['en', 'ar']),
  color: new JsonField('color', label('color'), ['en', 'ar']),
  case: new JsonField('case', label('case'), ['en', 'ar']),
  pickUp: new JsonField('pickUp', label('pickUp'), ['lat', 'long', 'address']),
  receipt: new BooleanField('receipt', label('receipt')),
  package: new BooleanField('package', label('package')),
  sellingType: new EnumeratorField('sellingType', label('sellingType'), [
    { id: 'normal', label: enumeratorLabel('sellingType', 'normal') },
    { id: 'auction', label: enumeratorLabel('sellingType', 'auction') }
  ], { required: true }),
  status: new EnumeratorField('status', label('status'), [
    { id: 'pendingadd', label: enumeratorLabel('status', 'pendingadd') },
    { id: 'pendingupdate', label: enumeratorLabel('status', 'pendingupdate') },
    { id: 'removed', label: enumeratorLabel('status', 'removed') },
    { id: 'sold', label: enumeratorLabel('status', 'sold') },
    { id: 'shadow', label: enumeratorLabel('status', 'shadow') },
    { id: 'approved', label: enumeratorLabel('status', 'approved') },
    { id: 'rejected', label: enumeratorLabel('status', 'rejected') }
  ], { required: true }),
  
  endTime: new DateTimeField('endTime', label('endTime'), { required: true }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class ProductModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
