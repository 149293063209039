<template>
  <div
    id="products"
    aria-labelledby="products-tab"
    role="tabpanel"
    class="tab-pane active"
  >
    <!-- BEGIN: HTML Table Data -->
    <div class="relative">
      <!-- <loading-spinner
        type="controller"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      /> -->
      <div class="overflow-x-auto">
        <div
          class="flex flex-col sm:flex-row gap-3 justify-end mt-5 sm:mt-0 sm:my-3"
        >
          <button
            :disabled="btnLoading"
            class="btn bg-theme-33 text-black sm:w-auto pb-2 pt-2 relative"
            @click="onExportXlsx"
          >
            <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
            <loading-spinner
              type="half-circle"
              :duration="1000"
              :size="20"
              color="#4469A8"
              :loading="btnLoading"
            />
            {{ i18n('common.table.exportOptions.exportProducts') }}
          </button>
          <a
            class="btn bg-theme-31 text-white sm:w-auto pb-2 pt-2"
            href="javascript:;"
            data-toggle="modal"
            :data-target="'#' + modalId"
          >
            <RefreshCwIcon class="w-5 h-5 mr-2 ml-2" />
            {{ i18n('common.refresh') }}
          </a>
        </div>
        <!-- scrollbar-hidden -->
        <table
          id="productsTable"
          ref="tableRef"
          class="table table-report sm:mt-2"
        >
          <thead>
            <tr>
              <th
                v-for="col in columns"
                :key="col.name"
                class="text-center whitespace-nowrap"
                :class="{ 'cursor-pointer': col.sortable }"
              >
                <button
                  @click="sortTableByCol(col)"
                  class="font-medium"
                  v-if="col.sortable"
                >
                  <arrow-down-icon
                    size="1x"
                    class="font-medium"
                    v-if="this.sortType === 'desc'"
                  ></arrow-down-icon>
                  <arrow-up-icon
                    size="1x"
                    class="font-medium"
                    v-if="this.sortType === 'asc'"
                  ></arrow-up-icon>
                  {{ i18n(col.label) }}
                </button>
                <span v-else>{{ i18n(col.label) }}</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="rows.length">
            <tr v-for="(row, index) in rows" :key="index">
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap flex gap-4 justify-center"
                >
                  {{ presenter(row, 'id') }}
                  <CopyIcon
                    class="cursor-pointer text-green-600"
                    @click="copyPassword(row.id)"
                  />
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'provider_name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterCurrency(row.price, row.currency) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.product')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      ></Pagination>
    </div>
    <refresh-modal :id="modalId" @action="() => refresh(currentType)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import Message from '@/shared/message/toastify'
import { GenericModel } from '@/shared/model/generic-model'
import { ProductModel } from '@/store/product/product-model'
import { helper as $h } from '@/utils/helper'
import axios from 'axios'
import refreshModal from '@/components/modals/refresh-modal.vue'

const { fields } = ProductModel

export default defineComponent({
  name: 'app-product-list-table',
  props: {
    tab: {
      type: String,
      default: 'products',
      required: true
    },
    filters: {
      type: Array,
      default: () => []
    },
    currentType: {
      type: String,
      required: true
    }
  },
  setup() {
    const tabulator = ref()
    const tableRef = ref()

    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tabulator,
      tableRef
    }
  },
  components: {
    'refresh-modal': refreshModal
  },
  data() {
    return {
      selectedId: null,
      btnLoading: false,
      selectedProduct: null,
      listOfRows: [],
      sortBy: null,
      modalId: 'refresh-products-modal',
      columns: [
        {
          name: 'id',
          field: 'id',
          label: 'product.fields.sku',
          align: 'center'
          // required: true,
        },
        {
          name: 'title',
          field: 'title',
          label: 'product.fields.title',
          align: 'center'
          // required: true,
        },
        {
          name: 'providerName',
          field: 'providerName',
          label: 'product.fields.providerName',
          align: 'center'
          // required: true,
        },
        {
          name: 'price',
          field: 'price',
          label: 'product.fields.price',
          align: 'center'
          // required: true,
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      products: 'product/list/rows',
      currentPage: 'product/list/currentPage',
      isFirstPage: 'product/list/isFirstPage',
      isLastPage: 'product/list/isLastPage',
      pagination: 'product/list/pagination',
      listLoading: 'product/list/loading',
      destroyLoading: 'product/destroy/loading',
      saveLoading: 'product/form/saveLoading'
    }),
    rows: {
      get() {
        return this.listOfRows.length ? this.listOfRows : this.products
      },
      set(value) {
        this.listOfRows = value
      }
    },
    sortType: {
      get() {
        return this.sortBy ? this.sortBy : this.pagination.sortBy
      },
      set(value) {
        this.sortBy = value
      }
    },
    fields() {
      return fields
    },
    loading() {
      return this.listLoading || this.destroyLoading || this.saveLoading
    },
    // currentPage() {
    //   return this.pagination.currentPage || 1
    // },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      refresh: 'product/list/refresh',
      doFetch: 'product/list/doFetch',
      doFetchNextPage: 'product/list/doFetchNextPage',
      doFetchPreviousPage: 'product/list/doFetchPreviousPage',
      doMountTable: 'product/list/doMountTable',
      doChangeSort: 'product/list/doChangeSort',
      doChangePaginationCurrentPage:
        'product/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'product/list/doChangePaginationPageSize',
      doToggleUserAccountStatus: 'product/view/doToggleUserAccountStatus',
      doDestroy: 'product/destroy/doDestroy',
      doAccept: 'product/form/doAccept',
      doReject: 'product/form/doReject'
    }),
    copyPassword(text) {
      navigator.clipboard.writeText(text)
      Message.success(this.i18n('common.clipboard'))
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    toggleSortType() {
      if (!this.sortBy) {
        this.sortBy = this.pagination.sortBy
      }
      this.sortType = this.sortBy == 'desc' ? 'asc' : 'desc'
    },
    onExportXlsx() {
      const exportFromBackend = true
      const currentType = this.$props.currentType
      if (exportFromBackend) {
        this.btnLoading = true
        axios({
          url: `https://europe-west1-shamy-stores-8a2dd.cloudfunctions.net/rest/exportProductsToExcel?provider_name=${currentType}`,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'Products.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
          this.btnLoading = false
        })
      } else {
        $h.exportToXlsx(this.tableRef, 'productsTable', 'products')
      }
    },
    sortTableByCol(field) {
      if (this.listOfRows.length === 0) {
        this.listOfRows = [...this.products]
      }
      const executeSorting = (compareFunction) => {
        this.rows = this.listOfRows.sort(compareFunction)
      }

      const fieldType = field.dataType
      const fieldName = field.name

      if (fieldType === 'date') {
        this.toggleSortType()
        if (this.sortType === 'asc') {
          // ✅ Sort in Ascending order (low to high)
          executeSorting(
            (objA, objB) =>
              new Date(objA[fieldName]).getTime() -
              new Date(objB[fieldName]).getTime()
          )
        }

        if (this.sortType === 'desc') {
          // ✅ Sort in Descending order (high to low)
          executeSorting(
            (objA, objB) =>
              new Date(objB[fieldName]).getTime() -
              new Date(objA[fieldName]).getTime()
          )
        }
      } else if (fieldType === 'number') {
        this.toggleSortType()
        if (this.sortType === 'asc') {
          // ✅ Sort in Ascending order (low to high)
          executeSorting(
            (objA, objB) =>
              parseFloat(objA[fieldName]) - parseFloat(objB[fieldName])
          )
        }

        if (this.sortType === 'desc') {
          // ✅ Sort in Descending order (high to low)
          executeSorting(
            (objA, objB) =>
              parseFloat(objB[fieldName]) - parseFloat(objA[fieldName])
          )
        }
      }
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} دولار` : `${PRICE} USD`
    },
    presenter(row, fieldName) {
      return ProductModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter((el) => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    roundNumber(number) {
      return (Math.round(number * 100) / 100).toFixed(2)
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    presenterCurrency(price, currency) {
      const PRICE = this.roundNumber(price)
      const amount = this.numberWithCommas(PRICE)
      return currency === 'USD'
        ? this.language == 'ar'
          ? `${amount || 0} دولار`
          : `${amount || 0} USD`
        : this.language == 'ar'
        ? `${amount || 0} جنية`
        : `${amount || 0} EGP`
    },
    async doNextPage() {
      this.rows = []
      this.sortType = null
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      this.rows = []
      this.sortType = null
      await this.doFetchPreviousPage()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
